import { createTheme } from "@mui/material/styles"
import { createBreakpoints } from "@mui/system"

const fontFamilies = {
  subheading: "Open Sans",
}

const colors = {
  primary: "#5570F1",
  secondary: "#5570F1",
  muted: "#5570F1",
  success: "#5570F1",
  info: "#5570F1",
  warning: "#5570F1",
  danger: "#E53E3E",
  white: "#FFFFFF",
  black: "#000000",
  backgroundColor: "#EEF0FA",
  bgColorLight: "#5570F1",
  hyperlink: "#7F7F7F",
  nightRider: "#2E2E2E",
  softBlue: "#A0AEC0",
  greyShade: "#777777",
  darkBlue: "#1890ff",
  grey: "#177ddc",
  lightBlue: "#d9e3f0",
  lightPink: "#FFCC91",
}

const breakpoints = createBreakpoints({})

export const theme = createTheme({
  // typography: {
  //   fontSize: 16,
  //   fontFamily: fontFamilies.subheading,
  //   h1: {
  //     color: "#E53E3E",
  //     fontWeight: 700,
  //     fontSize: "52px",
  //     lineHeight: "78px",
  //   },
  //   h2: {
  //     fontSize: "25px",
  //     lineHeight: "30px",
  //   },
  //   h3: {
  //     fontWeight: 700,
  //     fontSize: "21px",
  //     lineHeight: "31px",
  //     opacity: 0.8,
  //   },
  //   h4: {
  //     fontSize: "1.5rem",
  //   },
  //   h5: {
  //     fontWeight: 600,
  //     fontSize: "14px",
  //     [breakpoints.down("md")]: {
  //       fontSize: "12px",
  //     },
  //     lineHeight: "21px",
  //     opacity: 0.8,
  //   },
  //   h6: {
  //     fontWeight: 600,
  //     fontSize: "12px",
  //     [breakpoints.down("md")]: {
  //       fontSize: "10px",
  //     },
  //     lineHeight: "18px",
  //     opacity: 0.8,
  //   },
  //   subtitle1: {
  //     fontSize: "11px",
  //     lineHeight: "16px",
  //     opacity: 0.8,
  //   },
  //   subtitle2: {
  //     fontWeight: 500,
  //     fontSize: "0.7rem",
  //     lineHeight: 1.2,
  //   },
  //   body1: {},
  //   body2: {},
  //   caption: {},
  //   overline: {},
  // },
  typography: {
    fontSize: 16,
    fontFamily: fontFamilies.subheading,
    h1: {
      color: "#E53E3E",
      fontWeight: 700,
      fontSize: "52px",
      lineHeight: "78px",
    },
    h2: {
      color: colors.primary,
      fontSize: "25px",
      lineHeight: "30px",
    },
    h3: {
      color: colors.black,
      fontWeight: 700,
      fontSize: "21px",
      lineHeight: "31px",
      opacity: 0.8,
    },
    h4: {
      color: colors.black,
      fontWeight: 700,
      fontSize: "18px",
      [breakpoints.down("md")]: {
        fontSize: "14px",
      },
      lineHeight: "27px",
      opacity: 0.8,
    },
    h5: {
      color: colors.black,
      fontWeight: 600,
      fontSize: "14px",
      [breakpoints.down("md")]: {
        fontSize: "12px",
      },
      lineHeight: "21px",
      opacity: 0.8,
    },
    h6: {
      color: colors.black,
      fontWeight: 600,
      fontSize: "12px",
      [breakpoints.down("md")]: {
        fontSize: "10px",
      },
      lineHeight: "18px",
      opacity: 0.8,
    },
    subtitle1: {
      color: colors.black,
      fontSize: "11px",
      lineHeight: "16px",
      opacity: 0.8,
    },
    subtitle2: {
      color: colors.hyperlink,
      fontSize: "10px",
      lineHeight: "16px",
    },
    body1: {},
    body2: {},
    button: {
      color: colors.white,
      fontSize: "12px",
      lineHeight: "14px",
      fontFamily: "Roboto",
      fontWeight: 700,
    },
    caption: {},
    overline: {},
  },
})
