import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { AxiosInst } from "service/axios"

const loadResources = async (locale) => {
  if (locale === "en") locale = "en_US"
  return await AxiosInst.get("/1/" + locale + ".json")
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split("|")
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        })
      })
    } catch (e) {
      console.error(e)
      callback(null, {
        status: 500,
      })
    }
  },
}

const DETECTION_OPTIONS = {
  order: ["querystring", "navigator", "localStorage"],
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    ln: "en",
    fallbackLng: "en",
    detection: DETECTION_OPTIONS,
    interpolation: {
      escapeValue: false,
    },

    backend: backendOptions,
    load: "languageOnly",
    ns: ["translations"],
    defaultNS: "translations",

    react: {
      wait: true,
    },
  })

export default i18n
