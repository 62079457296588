import { Box } from "@mui/material"
import NavBar from "components/NavBar"

const Base = (props: any) => {
  return (
    <Box
      sx={{
        bgcolor: "backgroundColor.main",
      }}
    >
      <NavBar />
      {props.children}
    </Box>
  )
}

export default Base
