import axios, { AxiosInstance } from "axios"
import i18n from "i18n"
import { toast } from "react-toastify"

const KeyCloakAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_BASE_URL,
  timeout: 500,
})

KeyCloakAxios.interceptors.request.use(function (request: any) {
  const KeyClockBearerToken = localStorage.getItem("KEYCLOAK_ADMIN_TOKEN")
  request.headers.Authorization = `Bearer ${KeyClockBearerToken}`
  return request
})

KeyCloakAxios.interceptors.response.use(
  function (response: any) {
    return response
  },
  function (error: any) {
    if (!error.response) toast.error(i18n.t("toast_message.serverError"))
    if (error?.response?.data?.error === "invalid_grant") {
      toast.error(i18n.t("toast_message.invalid"))
    }
    throw error
  }
)

const BaseAxios: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_GWX_API_BASE_URL}`,
})

const Axios: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
})

Axios.interceptors.request.use(function (request: any) {
  const bearerToken = localStorage.getItem("ACCESS_TOKEN")
  request.headers.Authorization = `Bearer ${bearerToken}`
  return request
})

Axios.interceptors.response.use(
  function (response: any) {
    return response
  },
  function (error: any) {
    if (error.response.status === 401) {
      localStorage.removeItem("ACCESS_TOKEN")
      localStorage.removeItem("KEYCLOAK_ADMIN_TOKEN")
      localStorage.removeItem("session")
      localStorage.removeItem("rights")
      window.location.href = "/signin"
      toast.error(
        i18n.t(
          error?.response?.data?.error === "active_session_exists"
            ? "toast_message.sessionValidationMessage"
            : "toast_message.unauthorized"
        )
      )
    } else if (error.response.status === 429) {
      toast.error(i18n.t("toast_message.tooManyRequests"))
    }
  }
)

const AxiosInst: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_TRANSLATIONS_URL}`,
})

BaseAxios.interceptors.request.use(function (request: any) {
  const bearerToken = localStorage.getItem("ACCESS_TOKEN")
  request.headers.Authorization = `Bearer ${bearerToken}`
  return request
})

BaseAxios.interceptors.response.use(function (response: any) {
  return response
})

export { BaseAxios, AxiosInst, KeyCloakAxios, Axios }
