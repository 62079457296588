import { combineReducers } from "@reduxjs/toolkit"
import { fork, all } from "redux-saga/effects"
import { authSaga } from "./auth/saga"
import { commonSaga } from "./common/saga"
import { reducer as authReducer } from "./auth/slice"
import { reducer as commonReducer } from "./common/slice"

export function* rootSaga() {
  yield all([fork(authSaga), fork(commonSaga)])
}

export function rootReducer(injectedReducers = {}) {
  return combineReducers({
    auth: authReducer,
    common: commonReducer,
    ...injectedReducers,
  })
}
