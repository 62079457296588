const apiEndPoints = {
  dashboardDetails: "/dashboard",
  chartDetails: "/chartV3",
  filters: "/filter",
  user: {
    getUserByEmail: "getUserByEmail",
    saveProfileDetails: "saveProfile",
    loginData: "page-content/all",
    getUserAccess: "user/userAccess",
    logout: "auth/logout",
  },
  tenants: {
    getTenantDetails: "getTenantDetails",
  },
  commonEndPoints: {
    getSignedUrl: "projects/getSignedUrl",
  },
  twoFactorAuth: {
    enableMFA: "twoFactorAuth/enable",
    verifyCode: "twoFactorAuth/verify",
  },
  verifyEmail: `userProfile/verify-email`,
  getPolicy: "policy/getPolicy",
}

export { apiEndPoints }
