import React from "react";
import { CircularProgress, Box } from "@mui/material";

const PageLoader: React.FC = () => (
  <Box
    sx={{
      position: "fixed",
      bottom: 0,
      right: 0,
      top: 0,
      left: 0,
      textAlign: "center",
      display: "flex",
      height: "100vh",
    }}
  >
    <CircularProgress sx={{ margin: "auto" }} size={25} />
  </Box>
);

export default PageLoader;
