import * as ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import storeInstance from "./store/configureStore"
import { ThemeProvider } from "@mui/material"
import { theme } from "./styles/theme"
import AppRoutes from "./routes/Routes"
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "App.css"
import "i18n"

const { store } = storeInstance

const root = ReactDOM.createRoot(document.getElementById("root")!)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
)
