import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

const saveProfileDetails: any = createAction<{ payload: any }>(
  'common/saveProfileDetails'
)
const verifyEmail = createAction<{ id: string }>('common/verifyEmail')
const getSignedUrl = createAction<any>('common/getSignedUrl')

const networkCallRequest = createAction<any>('common/networkCallRequest')
const networkCallResponse = createAction<any>('common/networkCallResponse')

export const initialState: any = {
  userProfileDetails: [],
  emailVerified: false,
  signedUrl: '',
  networkCall: {
    loading: false,
    hasMoreData: true,
    error: null,
    pendingRequests: [],
  },
  networkError: null,
}

const commonSlice: any = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setProfileDetails(state, action: PayloadAction<any>) {
      state.userProfileDetails = action.payload
    },
    setIsEmailVerified(state, action: PayloadAction<any>) {
      state.emailVerified = action.payload
    },
    setSignedUrl(state, action: PayloadAction<any>) {
      state.signedUrl = action.payload
    },

    setNetworkState(state, action: PayloadAction<any>) {
      state.networkError = action.payload
    },
    setNetworkCallRequestConfig(state, action: PayloadAction<any>) {
      state.networkCall = {
        ...state.networkCall,
        ...action.payload,
      }
    },
    setNetworkCallResponseConfig(state, action: PayloadAction<any>) {
      state.networkCall = {
        ...state.networkCall,
        ...action.payload,
      }
    },
  },
})

export {
  saveProfileDetails,
  verifyEmail,
  getSignedUrl,
  networkCallRequest,
  networkCallResponse,
}

export const { actions, reducer, name: sliceKey } = commonSlice
