import React from "react"
import { Box } from "@mui/material"
import Background from "assets/background.svg"

type ContainerProps = {
  children: React.ReactNode
}
const BasePage = (props: ContainerProps) => (
  <Box
    sx={{
      backgroundImage: `url(${Background})`,
      backgroundSize: "cover",
      backgroundColor: "#EEF0FA",
      height: "100vh",
    }}
  >
    {props.children}
  </Box>
)

export default BasePage
