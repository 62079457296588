import { call, takeLatest, all, put } from 'redux-saga/effects'
import {
  actions,
  fetchChartDetails,
  fetchTenantDetailsFromToken,
  getDashboardDetails,
  getFilterDetails,
  getQRCode,
  logOutRequest,
  loginDataRequest,
  loginRequest,
  policyRequest,
  verifyTwoFactorAuth,
  identityProviderLoginRequest,
} from './slice'
import { Axios, BaseAxios, KeyCloakAxios } from 'service/axios'
import { apiEndPoints } from 'service/variables'
import { LoginStatusEnum, PolicyType, Rights } from 'enums'
import { toast } from 'react-toastify'
import i18n from 'i18n'

function* getDashDetails() {
  const response: any = yield call(
    Axios.get,
    `${apiEndPoints.dashboardDetails}`,
    {
      params: {
        tenant_id: 1,
        dashboard_id: 1,
      },
    }
  )
  yield put(actions.setDashboardDetails(response?.data?.dashboard))
  // yield call(getFilters, { payload: { tenant_id: 1, dashboard_id: "1" } })
}

function* getFilters({ payload: { tenant_id, dashboard_id } }) {
  const response: any = yield call(Axios.get, `${apiEndPoints.filters}`, {
    params: {
      tenant_id,
      dashboard_id,
    },
  })

  yield put(actions.setDashboardFilters(response?.data))
}

function* getChartDetails({
  payload: {
    tenant_id,
    chart_id,
    dashboard_id,
    page_size,
    page_no,
    sort_by,
    sort_order,
    filters,
  },
}) {
  const queryString = `?tenant_id=${tenant_id}&chart_id=${chart_id}&dashboard_id=${dashboard_id}&page_size=${page_size}&page_no=${page_no}&sort_by=${sort_by}&sort_order=${sort_order}`
  const response: any = yield call(
    Axios.post,
    `${apiEndPoints.chartDetails}${queryString}`,
    filters
  )

  yield put(actions.setLoader(false))

  yield put(
    actions.setChartData({
      [tenant_id + '_' + dashboard_id + '_' + chart_id]: response.data,
    })
  )
}
function* identityProviderLogin({
  payload: {
    clientId,
    clientSecret,
    code,
    redirectUrl,
    realmName,
    history
  },
}: ReturnType<typeof identityProviderLoginRequest>): Generator {
  try{
    const params = new URLSearchParams()
    params.append(
      'grant_type',
      `${process.env.REACT_APP_KEYCLOAK_SSO_GRANT_TYPE}`
    )
    params.append('client_id', clientId)
    params.append('client_secret', clientSecret)
    params.append('code', code)
    params.append('redirect_uri', redirectUrl)
    const loginResponse: any = yield call(
      KeyCloakAxios.post,
      `realms/${realmName}/protocol/openid-connect/token`,
      params
    )
    const data = loginResponse?.data

  if (data?.access_token) {
      localStorage.setItem('ACCESS_TOKEN', data.access_token);
  }
      else {
      toast.error(i18n.t('login.invalid_creds'))
  }
  } catch (err) {
    console.log(err)
  }
}

function* userLogin({
  payload: { username, password, clientId, clientSecret, realmName, history },
}: ReturnType<typeof loginRequest>): Generator {
  try {
    const params = new URLSearchParams()
    params.append('username', username)
    params.append('password', password)
    params.append('grant_type', `${process.env.REACT_APP_GRANT_TYPE}`)
    params.append('client_id', clientId)
    params.append('client_secret', clientSecret)

    const loginResponse: any = yield call(
      KeyCloakAxios.post,
      `realms/${realmName}/protocol/openid-connect/token`,
      params
    )
    const data = loginResponse.data

    if (data?.access_token) {
      localStorage.setItem('ACCESS_TOKEN', data.access_token)
      yield call(getUserDetails, username, history)
    } else {
      toast.error(i18n.t('login.invalid_creds'))
    }
  } catch (err) {
    console.log(err)
  }
}

function* getUserDetails(email: any, history: any) {
  const response: any = yield call(
    Axios.get,
    `${apiEndPoints.user.getUserByEmail}`,
    { params: { email } }
  )
  const { privacyPolicy, termsAndConditions, hasEmailIDVerified } =
    response?.data?.data

  localStorage.setItem(
    'session',
    JSON.stringify({
      ...response?.data?.data,
      LoginStatus: LoginStatusEnum.TwoFactorChallenge,
    })
  )
  yield put(actions.setUserData(response?.data?.data))

  if (hasEmailIDVerified) {
    if (termsAndConditions !== undefined) {
      yield put(actions.setTermsAndConditions(termsAndConditions))
      yield put(actions.setTermsConditionsModalStatus(true))
    } else if (privacyPolicy !== undefined) {
      yield put(actions.setPrivacyPolicy(privacyPolicy))
      yield put(actions.setPrivacyPolicyModalStatus(true))
    } else {
      if (response?.data?.data?.twoFactorAuthEnabled) {
        history('/two-factor-auth')
      } else {
        history('/qr-auth')
      }
    }
  } else {
    toast.error(i18n.t('login.verify_email'))
  }
}

function* getTenantDetails() {
  const response: any = yield call(
    BaseAxios.get,
    `${apiEndPoints.tenants.getTenantDetails}`
  )

  if (response?.data) {
    localStorage.setItem('logo', response?.data?.logoSettings?.logo)
    yield put(actions.setTenantDetailsFromToken(response?.data))
  }
}

function* getPolicyData(): Generator {
  try {
    const termsConditionsResponse: any = yield call(
      BaseAxios.post,
      `${apiEndPoints.getPolicy}`,
      {
        type: PolicyType.TermsAndConditions,
        tenantRegion: 'any',
        language: 'en',
      }
    )

    yield put(
      actions.setPolicyData({
        data: termsConditionsResponse?.data?.data,
        type: PolicyType.TermsAndConditions,
      })
    )

    const privacyPolicyResponse: any = yield call(
      BaseAxios.post,
      `${apiEndPoints.getPolicy}`,
      {
        type: PolicyType.PrivacyPolicy,
        tenantRegion: 'any',
        language: 'en',
      }
    )

    yield put(
      actions.setPolicyData({
        data: privacyPolicyResponse?.data?.data,
        type: PolicyType.PrivacyPolicy,
      })
    )

    const cookiePolicyResponse: any = yield call(
      BaseAxios.post,
      `${apiEndPoints.getPolicy}`,
      {
        type: PolicyType.CookiePolicy,
        tenantRegion: 'any',
        language: 'en',
      }
    )

    yield put(
      actions.setPolicyData({
        data: cookiePolicyResponse?.data?.data,
        type: PolicyType.CookiePolicy,
      })
    )
  } catch (err) {
    console.log(err)
  }
}

function* getLoginData({ payload: { page } }): Generator {
  try {
    const response: any = yield call(
      BaseAxios.post,
      `${apiEndPoints.user.loginData}`,
      {
        page,
      }
    )
    yield put(actions.setLoginData(response?.data?.data))
  } catch (err) {
    console.log(err)
  }
}

function* verifyTwoFactorToken({
  payload: { twoAuthToken, id, keyCloakUserId, roleName, history },
}): Generator {
  const response: any = yield call(
    BaseAxios.post,
    `${apiEndPoints.twoFactorAuth.verifyCode}`,
    { twoAuthToken, id }
  )
  if (response?.data?.data) {
    const session = JSON.parse(localStorage.getItem('session')!)
    localStorage.setItem(
      'session',
      JSON.stringify({ ...session, LoginStatus: LoginStatusEnum.LoggedIn })
    )
    yield call(getUserAccess, { payload: { roleName, history } })
  } else {
    toast.error(i18n.t('login.invalid_mfa_code'))
  }
}

function* getQRBarCode({ payload: { id } }) {
  const { _id } = JSON.parse(localStorage.getItem('session')!)
  const response: any = yield call(
    BaseAxios.post,
    `${apiEndPoints.twoFactorAuth.enableMFA}`,
    { id: _id }
  )
  yield put(actions.setQRCodeData(response))
}

function* getUserAccess({ payload: { roleName, history } }): Generator {
  try {
    const response: any = yield call(
      BaseAxios.post,
      `${apiEndPoints.user.getUserAccess}`,
      { roleName }
    )
    if (response.status === 200) {
      const rights = response?.data?.data
      yield put(actions.setUserAccess(rights))
      localStorage.setItem('rights', JSON.stringify(rights))
      const session = JSON.parse(localStorage.getItem('session')!)
      if (
        session?.LoginStatus === LoginStatusEnum.LoggedIn &&
        rights.includes(Rights.ViewDashboard)
      ) {
        history('/home')
      } else {
        localStorage.clear()
        history('/unauthorized')
      }
    }
  } catch (err) {
    console.log(err)
  }
}

function* userLogout({ payload: { history } }): Generator {
  try {
    const session = JSON.parse(localStorage.getItem('session')!)
    const response: any = yield call(
      BaseAxios.post,
      `${apiEndPoints.user.logout}`,
      { id: session?._id }
    )
    if (response?.status === 200) {
      localStorage.removeItem('ACCESS_TOKEN')
      localStorage.removeItem('KEYCLOAK_ADMIN_TOKEN')
      localStorage.removeItem('session')
      localStorage.removeItem('rights')
      history('/signin')
      toast.success(i18n.t('logout.logout_success'))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* authSaga() {
  yield all([
    yield takeLatest(loginRequest, userLogin),
    yield takeLatest(getDashboardDetails, getDashDetails),
    yield takeLatest(fetchChartDetails, getChartDetails),
    yield takeLatest(getFilterDetails, getFilters),
    yield takeLatest(fetchTenantDetailsFromToken, getTenantDetails),
    yield takeLatest(policyRequest, getPolicyData),
    yield takeLatest(loginDataRequest, getLoginData),
    yield takeLatest(verifyTwoFactorAuth, verifyTwoFactorToken),
    yield takeLatest(getQRCode, getQRBarCode),
    yield takeLatest(identityProviderLoginRequest, identityProviderLogin),
    yield takeLatest(logOutRequest, userLogout),
  ])
}
