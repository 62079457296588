import { configureStore } from "@reduxjs/toolkit"
// import { forceReducerReload } from "redux-injectors"
import createSagaMiddleware from "redux-saga"
import { rootReducer, rootSaga } from "./ducks"

const store = function configureAppStore() {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

  const middleWares = [sagaMiddleware]

  const store = configureStore({
    reducer: rootReducer(),
    middleware: [...middleWares],
    devTools: process.env.NODE_ENV !== "production",
  })

  sagaMiddleware.run(rootSaga)
  // if (module.hot) {
  //   module.hot.accept("./ducks", () => {
  //     forceReducerReload(store)
  //   })
  // }

  return { store }
}

export default store()
