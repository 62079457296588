import { Suspense, lazy } from "react"
import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import Base from "layouts/Base"
import { LoginStatusEnum } from "enums"
import BasePage from "layouts/BasePage"
import PageLoader from "components/Elements/PageLoader"

const Home = lazy(() => import("components/Home"))
const Login = lazy(() => import("components/SignIn"))
const TwoFactorAuth = lazy(
  () => import("components/QRCodeAuthentication/TwoFactorAuth")
)
const QRAuth = lazy(() => import("components/QRCodeAuthentication/QRAuth"))
const Unauthorized = lazy(() => import("components/Unauthorized"))

const listofPages: string[] = [
  "/",
  "/signin",
  "/signup",
  "/verify-email",
  "/account-verification",
  "/forgot-password",
  "/reset-password",
  "/qr-auth",
  "/two-factor-auth",
  "/unauthorized",
]

const AppRoutes = () => {
  const location = useLocation()

  const authRoute = (component: any, roles: any = []) => {
    const token = localStorage.getItem("ACCESS_TOKEN")
    const session = localStorage.getItem("session")
    if (session) {
      const { LoginStatus } = JSON.parse(session)
      return token && LoginStatus === LoginStatusEnum.LoggedIn ? (
        component
      ) : (
        <Navigate replace to="/signin"></Navigate>
      )
    } else {
      return <Navigate replace to="/signin"></Navigate>
    }
  }

  if (
    listofPages.indexOf(location!.pathname) > -1 ||
    /\/verify-email\/.*/.test(location!.pathname)
  ) {
    return (
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Routes location={location}>
            <Route path="/" element={<Login />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/two-factor-auth" element={<TwoFactorAuth />} />
            <Route path="/qr-auth" element={<QRAuth />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
        </Suspense>
      </BasePage>
    )
  }

  return (
    <Base>
      <Suspense fallback={<PageLoader />}>
        <Routes location={location}>
          <Route path="/home" element={authRoute(<Home />)} />
        </Routes>
      </Suspense>
    </Base>
  )
}
export default AppRoutes
