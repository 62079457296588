export enum LoginStatusEnum {
  LoggedIn = "logged_in",
  TwoFactorChallenge = "two_factor_challenge",
}

export enum PolicyType {
  PrivacyPolicy = "Privacy Policy",
  TermsAndConditions = "Terms and Conditions",
  CookiePolicy = "Cookie Policy",
}

export enum Rights {
  ViewDashboard = "View Artifacts",
}
