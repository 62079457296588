import { call, takeLatest, all, put } from "redux-saga/effects"
import { saveProfileDetails, verifyEmail, actions } from "./slice"
import { apiEndPoints } from "service/variables"
import { BaseAxios } from "service/axios"
import { toast } from "react-toastify"
import i18n from "i18n"

function* saveUserDetails({ payload }) {
  const response: any = yield call(
    BaseAxios.post,
    `${apiEndPoints.user.saveProfileDetails}`,
    { payload }
  )
  toast.success(i18n.t("toast_message.createUser"))
  console.log(response)
}

export function* emailVerification({
  payload: { id },
}: ReturnType<typeof verifyEmail>): any {
  try {
    const response = yield call(BaseAxios.post, apiEndPoints.verifyEmail, {
      inviteId: id,
    })

    if (response?.data?.data?.hasEmailIDVerified) {
      yield put(actions.setIsEmailVerified(true))
      toast.success(i18n.t("toast_message.email"))
    }
  } catch (err) {
    console.log(err)
  }
}

// export function* fetchSignedUrl({
//   payload: { url, fileName = "", isForDownload = false },
// }) {
//   try {
//     const response = yield call(
//       BaseAxios.get,
//       apiEndPoints.commonEndPoints.getSignedUrl,
//       {
//         params: {
//           url,
//         },
//       }
//     )
//     yield put(actions.setSignedUrl(response?.data?.data))

//     if (isForDownload) {
//       downloadFiles(response?.data?.data, fileName)
//     }
//   } catch (err) {
//     console.log(err)
//   }
// }

export function* commonSaga() {
  yield all([
    yield takeLatest(saveProfileDetails, saveUserDetails),
    yield takeLatest(verifyEmail, emailVerification),
    // yield takeLatest(getSignedUrl, fetchSignedUrl),
  ])
}
