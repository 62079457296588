import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { PolicyType } from 'enums'

export const initialState = {
  loginData: {
    data: '',
    images: [],
  },
  dashboardDetails: [],
  chartData: {},
  loading: false,
  dashboardFilters: [],
  tenantInfo: {},
  userData: {},
  userAccess: [],
  policyData: { privacyPolicy: {}, termsAndConditions: {}, cookiePolicy: {} },
  qrCodeData: '',
}

const getDashboardDetails: any = createAction<any>('auth/getDashboardDetails')
const fetchChartDetails: any = createAction<any>('auth/fetchChartDetails')
const getFilterDetails: any = createAction<any>('auth/getFilterDetails')

const loginRequest: any = createAction<{
  username: string
  password: string
  history: any
}>('auth/loginRequest')

const loginDataRequest: any = createAction<any>('auth/loginDataRequest')
const policyRequest: any = createAction<any>('auth/policyRequest')
const identityProviderLoginRequest: any = createAction<{
  code: string
  history: any
}>('auth/identityProviderLoginRequest')
const fetchTenantDetailsFromToken: any = createAction<{}>(
  'auth/tenantInfoFromToken'
)
const updateUserPolicyAcceptance: any = createAction<any>(
  'auth/updateUserPolicyAcceptance'
)
const logOutRequest: any = createAction<{ history: any }>('auth/logOutRequest')
const verifyTwoFactorAuth: any = createAction<{
  twoAuthToken: any
  id: any
  keyCloakUserId: any
  roleName: string
  history: any
}>('auth/verifyTwoFactorAuth')

const getQRCode: any = createAction<{ userId: string }>('auth/getQRCode')

const authSlice: any = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<any>) {
      state.userData = action.payload
    },
    setDashboardDetails(state, action: PayloadAction<any>) {
      state.dashboardDetails = action.payload
    },
    setChartData(state, action: PayloadAction<any>) {
      state.chartData = { ...state.chartData, ...action.payload }
    },
    setLoader(state, action: PayloadAction<any>) {
      state.loading = action.payload
    },
    setDashboardFilters(state, action: PayloadAction<any>) {
      state.dashboardFilters = action.payload
    },
    setTenantDetailsFromToken(state, action: PayloadAction<any>) {
      state.tenantInfo = action.payload
    },
    setLoginData(state, action: PayloadAction<any>) {
      state.loginData = action.payload
    },
    setPolicyData(state, action: PayloadAction<any>) {
      const { data, type } = action.payload
      if (type === PolicyType.TermsAndConditions) {
        state.policyData.termsAndConditions = data
      } else if (type === PolicyType.PrivacyPolicy) {
        state.policyData.privacyPolicy = data
      } else {
        state.policyData.cookiePolicy = data
      }
    },
    setUserAccess(state, action: PayloadAction<any>) {
      state.userAccess = action.payload
    },
    setQRCodeData(state, action: PayloadAction<any>) {
      state.qrCodeData = action.payload
    },
  },
})

export {
  getDashboardDetails,
  fetchChartDetails,
  getFilterDetails,
  loginRequest,
  loginDataRequest,
  policyRequest,
  identityProviderLoginRequest,
  fetchTenantDetailsFromToken,
  updateUserPolicyAcceptance,
  logOutRequest,
  verifyTwoFactorAuth,
  getQRCode,
}

export const { actions, reducer, name: sliceKey } = authSlice
